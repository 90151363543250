<template>
  <div class="contatain">
    <div class="tab">
      <!-- tab栏 -->
      <div
        class="tab-item"
        v-for="(item, index) in tabMenu"
        :key="`a${index}`"
        :class="curTab === item.id ? 'tab-item-active' : ''"
        @click="curTab = item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="content-wrap">
      <!-- 每日签到 -->
      <div v-if="curTab === 1" class="reward">
        <div v-for="(item, index) in signModelList" class="mb-10" :key="index">
          <div class="flex space-between">
            <div class="reward-left">
              <div class="reward-left-title must">签到名称</div>
              <el-input
                v-model="item.name"
                style="width: 200px"
                size="mini"
                placeholder="请输入名称"
              ></el-input>
            </div>
            <div class="flex">
              <div
                @click="
                  beforConfirm(remove, { text: '是否删除' }, item.id, index)
                "
                class="reward-btn1"
              >
                删除
              </div>
              <div @click="save(item, index)" class="reward-btn2">保存</div>
            </div>
          </div>

          <div class="reward-item">
            <div class="reward-item-title">签到限制:</div>
            <el-select
              size="mini"
              v-model="item.signLimit"
              placeholder="请选择"
            >
              <el-option
                v-for="signLimitItem in signLimitOptions"
                :key="signLimitItem.value"
                :label="signLimitItem.label"
                :value="signLimitItem.value"
              >
              </el-option>
            </el-select>
            <span class="ml-3">可签到;</span>
          </div>
          <div class="reward-item">
            <div class="reward-item-title">签到形式:</div>
            <el-button-group style="overflow: hidden; border-radius: 10px">
              <el-button
                round
                v-for="signItem in signOptions"
                :key="signItem.value"
                @click="(item.type = signItem.value), (item.costCount = 0)"
                :type="item.type === signItem.value ? 'primary' : ''"
                size="mini"
                >{{ signItem.label }}</el-button
              >
            </el-button-group>
          </div>
          <div class="reward-item">
            <div class="reward-item-title">补签费用:</div>
            <span
              class="mr-3"
              style="font-size: 14px"
              :style="{ color: item.type === 1 ? '#999999' : '#666' }"
              >1天:
            </span>
            <el-select
              :disabled="item.type === 1"
              size="mini"
              class="mr-2"
              style="width: 100px"
              v-model="item.costType"
              placeholder="请选择"
            >
              <el-option
                v-for="costTypeItem in costTypeOptions"
                :key="costTypeItem.value"
                :label="costTypeItem.label"
                :value="costTypeItem.value"
              >
              </el-option>
            </el-select>
            <el-input
              :disabled="item.type === 1"
              v-model.trim.number="item.costCount"
              size="mini"
              style="width: 100px"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="reward-item">
            <div class="reward-item-title">签到天数:</div>
            <!-- <el-input-number
              v-model.number="item.day"
               onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              size="mini"
              class="mr-2"
               placeholder="请输入天数"
              controls-position="right"
              :min="1"
              :max="31"
            ></el-input-number> -->
            <el-input
              style="width: 100px"
              onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
              v-model.number.trim="item.day"
              size="mini"
              @change="dayChange($event, item)"
              placeholder="请输入天数"
            ></el-input>
            天
          </div>
          <div class="reward-item" style="align-items: flex-start">
            <div class="reward-item-title">奖励设置:</div>
            <div class="flex-cloumn flex-1">
              <el-button-group style="overflow: hidden; border-radius: 10px">
                <el-button
                  round
                  v-for="rewardDayItem in rewardDayOptions"
                  :key="rewardDayItem.value"
                  @click="
                    beforConfirm(
                      changeRewardDay,
                      { text: '是否切换奖励设置，一旦切换将清除' },
                      item,
                      rewardDayItem
                    )
                  "
                  :type="
                    item.settingType === rewardDayItem.value ? 'primary' : ''
                  "
                  size="mini"
                  >{{ rewardDayItem.label }}</el-button
                >
              </el-button-group>
              <div class="reward-settingList">
                <div
                  style="width: 50%"
                  v-for="(settingItem, index) in item.settings"
                  :key="index"
                  class="flex flex-ac mt-2"
                >
                  <span class="mr-2">{{ index + 1 }}天：</span>
                  <el-select
                    class="mr-2"
                    style="width: 100px"
                    size="mini"
                    v-model="settingItem.key"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="costTypeItem in costTypeOptions"
                      :key="costTypeItem.value"
                      :label="costTypeItem.label"
                      :value="costTypeItem.value"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    style="width: 100px"
                    v-model.trim.number="settingItem.value"
                    size="mini"
                    placeholder="请输入数量"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 开局奖励 -->
      <div v-else class="reward">
        <div
          v-for="(item, index) in startrewardModelList"
          class="mb-10"
          :key="index"
        >
          <div class="flex space-between">
            <div class="reward-left">
              <div class="reward-left-title must">签到名称</div>
              <el-input
                v-model="item.name"
                style="width: 200px"
                size="mini"
                placeholder="请输入名称"
              ></el-input>
            </div>
            <div class="flex">
              <div
                @click="
                  beforConfirm(remove, { text: '是否删除' }, item.id, index)
                "
                class="reward-btn1"
              >
                删除
              </div>
            </div>
          </div>
          <div class="reward-item">
            <div class="reward-item-title">剧本类型:</div>
            <el-select size="mini" v-model="item.type" placeholder="请选择">
              <el-option
                v-for="scenarioTypeItem in scenarioTypeOptions"
                :key="scenarioTypeItem.value"
                :label="scenarioTypeItem.label"
                :value="scenarioTypeItem.value"
              >
              </el-option>
            </el-select>
            <span class="ml-3">剧本</span>
          </div>
          <div class="reward-item">
            <div class="reward-item-title">签到形式:</div>
            <el-input
              placeholder=""
              size="mini"
              style="width: 135px"
              v-model="item.count"
            >
              <div slot="prepend" class="flex-cc">
                <el-button
                  size="mini"
                  @click="item.count = item.count === 0 ? 1 : 0"
                  type="primary"
                  :style="{
                    background: item.count === 0 ? '#7f76bd' : '',
                    color: item.count === 0 ? '#fff' : '',
                  }"
                  >无限</el-button
                >
              </div>
            </el-input>
            <span class="ml-3">次</span>
          </div>
          <div class="reward-item">
            <div class="reward-item-title">奖励设置:</div>
            <el-select
              size="mini"
              class="mr-2"
              style="width: 100px"
              v-model="item.settingType"
              placeholder="请选择"
            >
              <el-option label="金币" :value="1"> </el-option>
            </el-select>
            <el-input
              v-model.trim.number="item.settingCount"
              size="mini"
              style="width: 100px"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="flex-cc">
        <el-button size="mini" @click="add" class="reward-addbtn"
          >添加</el-button
        >
      </div>
      <div class="flex-cc" v-if="curTab === 2">
        <el-button
          size="mini"
          type="danger"
          @click="save(startrewardModelList)"
          class="reward-addbtn2"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { myFill, deepCopy } from "@tools";
const apiName = {
  remove: ["deleteRewardSign", "deleteStartreward"],
  save: ["addRewardSign", "addStartreward"],
};

export default {
  components: {},
  data() {
    this.signOptions = [
      { label: "不可签到", value: 1 },
      { label: "可签到", value: 2 },
    ];
    this.signLimitOptions = [{ label: "所有用户", value: 0 }];
    this.rewardDayOptions = [
      { label: "按天设置", value: 1 },
      { label: "统一设置", value: 2 },
    ];
    this.costTypeOptions = [
      // { label: "点卷", value: 1 },
      { label: "金币", value: 2 },
      // { label: "商城商品", value: 3 },
    ];
    this.scenarioTypeOptions = [
      { label: "所有", value: 0 },
      { label: "点券", value: 1 },
      // { label: "商城商品", value: 3 },
    ];
    return {
      tabMenu: [
        { name: "每日签到", id: 1 },
        { name: "开局奖励", id: 2 },
      ],
      loading: false,
      // tab栏
      curTab: 1,
      list: [],
      detail: null,
      total: 100,
      options: [],
      value: "",
      settingList: [],
      signModelList: [
        {
          costCount: 0,
          costType: 2,
          day: 7,
          id: "",
          name: "",
          settingType: 1,
          signLimit: 0,
          type: 1,
          settings: [],
        },
      ],
      startrewardModelList: [
        {
          count: 0,
          id: "",
          name: "",
          settingCount: 0,
          settingType: 1,
          type: 0,
        },
      ],
      fiterData1: {
        currPage: 1,
        page: 50,
      },
      fiterData2: {
        currPage: 1,
        page: 50,
      },
    };
  },
  async created() {
    this.init();
  },
  methods: {
    init() {
      this.curTab === 1 ? this.getRewardSign() : this.getStartreward();
    },
    dayChange(day, item) {
      if(item.settingType !== 1) return
      if (day <= 30) {
        const len = item.settings.length;
        if ( len < day) {
          const obj = {
            value: "",
            key: 2,
          };
          item.settings = [...item.settings, ...myFill(obj, day - len)];
        } else {
           item.settings.splice(day)
        }
      } else {
        this.$message.warning("不能设置超过30天");
        item.day = 30;
      }
    },
    async remove(id, index) {
      if (id) {
        await this.$api.reward[apiName.remove[this.curTab - 1]](id);
        this.init();
      } else {
        this.signModelList.splice(index, 1);
      }
    },
    add() {
      if (this.curTab === 1) {
        if (this.signModelList.length) {
          return this.$message.warning("该功能暂未开放");
        }
        const [item] = this.$options.data().signModelList;
        this.changeRewardDay(item, 1);
        this.signModelList.push(item);
      } else {
        const [item] = this.$options.data().startrewardModelList;
        this.startrewardModelList.push(item);
      }
    },
    async getRewardSign() {
      const res = await this.$api.reward.getRewardSign(this.fiterData1);
      // res.data.forEach((item) => {
      //   let i = 1;
      //   item.settingList = [];
      //   const num = item.settingType === 1 ? 7 : 1;
      //   while (i <= num) {
      //     item.settingList.push({
      //       settingTypeCountDay: item[`settingTypeCountDay${i}`] || "",
      //       settingTypeDay: item[`settingTypeDay${i}`] || "",
      //     });
      //     i++;
      //   }
      // });
      this.signModelList = res.data;
    },
    async getStartreward() {
      const res = await this.$api.reward.getStartreward(this.fiterData2);
      this.startrewardModelList = res.data;
    },
    async save(items) {
      const model = this._mapData(items);
      const name = apiName.save[this.curTab - 1];
      await this.$api.reward[name](model);
      this.init();
    },
    _mapData(items) {
      if (this.curTab === 1) {
        items = deepCopy(items);
        items.costCount = +items.costCount;
        items.settings.forEach(item => item.value =  item.value || 0)
        // const settingObj = items.settingList.reduce((t, c, i) => {
        //   t[`settingTypeCountDay${i + 1}`] = c.settingTypeCountDay || 0;
        //   t[`settingTypeDay${i + 1}`] = c.settingTypeDay || 0;
        //   return t;
        // }, {});
        // this.$delete(items, "settingList");
        return [items]
      } else {
        return items;
      }
    },
    changeRewardDay(item, rewardDayItem) {
      item.settingType =
        typeof rewardDayItem === "number" ? rewardDayItem : rewardDayItem.value;
      const obj = {
        value: "",
        key: 2,
      };
      item.settings = myFill(obj, item.settingType === 1 ? item.day : 1);
    },
  },
  watch: {
    curTab() {
      this.init();
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/market.scss";
@import "./common/style/form.scss";
.item-date {
  text-align: left;
}
.isall {
  background: #7f76bd;
  color: #fff;
}

.reward {
  padding: 32px 8px;

  .must {
    position: relative;
  }
  .must::after {
    content: "*";
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
    color: red;
  }
  &-left {
    display: flex;
    &-title {
      position: relative;
      width: 80px;
      height: 24px;
      line-height: 24px;
      color: #333;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      background: #eceafa;
      border-radius: 4px;
      margin-right: 36px;
    }
  }
  &-btn1 {
    @include center(24px);
    width: 96px;
    cursor: pointer;
    background: #ffffff;
    border-radius: 4px;
    color: #f394ac;
    border: 1px solid #f394ac;
    margin-right: 20px;
  }
  &-btn2 {
    @include center(26px);
    width: 96px;
    cursor: pointer;
    background: #fae1ee;
    color: #333333;
    border-radius: 4px;
    margin-right: 20px;
  }
  &-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 14px;
    &-title {
      width: 70px;
      height: 24px;
      line-height: 24px;
      position: relative;
      padding: 0 28px 0 18px;
      font-size: 14px;
    }
    &-title::before {
      content: "";
      height: 24px;
      width: 4px;
      background: #eceafa;
      border-radius: 12px;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
    &-title::after {
      content: "*";
      position: absolute;
      top: 50%;
      right: 22px;
      transform: translateY(-50%);
      color: red;
    }
  }
  &-settingList {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
  }
  &-addbtn {
    margin: 0 auto;
    cursor: pointer;
  }
  &-addbtn2 {
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
  }
}
</style>